<template>
    <iframe style="width: 100%; height: 100vh; overflow: hidden; display: block;" frameborder=0 :src="conteudo.livro"></iframe>
</template>

<script>

import {mapState} from "vuex"
import axios from "axios"
export default {
    name: "LivroCompleto",
    components: {},
    props : ['id_conteudo'],
    data() {
        return {
            tab : null,
            conteudo : {}
        }
    },
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}aula/conteudo_get`, {id_conteudo : this.id_conteudo}).then( (res) => {
                this.conteudo = res.data
            })
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
